import { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import PriceCard from './PriceCard'
// import ComboPackageCard from './ComboPackageCard'
import Image1 from '../assets/most-popular.png'

import {
  WebsiteDesign,
  ECommerce,
  SocialMedia,
  SEO,
  VideoData,
  MarketingData,
  Logo,
  Illustration,
  Writing,
} from '../config/pricing'

const Prices = () => {
  console.log('PricingArray', WebsiteDesign)
  const [activeTab, setActiveTab] = useState('Website Design')
  const [tabData, setTabData] = useState([])

  useEffect(() => {
    if (activeTab == 'Website Design') {
      setTabData(WebsiteDesign)
    } else if (activeTab == 'E-Commerce Website') {
      setTabData(ECommerce)
    } else if (activeTab == 'Social Media') {
      setTabData(SocialMedia)
    } else if (activeTab == 'SEO') {
      setTabData(SEO)
    } else if (activeTab == 'Video Animation') {
      setTabData(VideoData)
    } else if (activeTab == 'Branding & Marketing Collateral') {
      setTabData(MarketingData)
    } else if (activeTab == 'Custom logo Package') {
      setTabData(Logo)
    } else if (activeTab == '2D & 3D Illustration') {
      setTabData(Illustration)
    } else if (activeTab == 'Content Writing') {
      setTabData(Writing)
    }
  }, [activeTab])
  return (
    <div className='price-container'>
      <div className='head'>
        <h2>MARKET’S MOST COMPETITIVE PRICES</h2>
      </div>
      <div className='tabs-cotainer'>
        <div className='top'>
          <div
            className={activeTab == 'Website Design' ? 'active-tab custom-tab' : 'custom-tab'}
            onClick={() => setActiveTab('Website Design')}
          >
            Website Design
          </div>
          <div
            className={activeTab == 'E-Commerce Website' ? 'active-tab custom-tab' : 'custom-tab'}
            onClick={() => setActiveTab('E-Commerce Website')}
          >
            E-Commerce Website
          </div>
          <div
            className={
              activeTab == 'Social Media' || activeTab == 'SEO'
                ? 'active-tab custom-tab'
                : 'custom-tab'
            }
            onClick={() => setActiveTab('Social Media')}
          >
            Digital Marketing
          </div>
          <div
            className={activeTab == 'Video Animation' ? 'active-tab custom-tab' : 'custom-tab'}
            onClick={() => setActiveTab('Video Animation')}
          >
            Video Animation
          </div>
        </div>
        <div className='bottom'>
          <div
            className={
              activeTab == 'Branding & Marketing Collateral'
                ? 'active-tab custom-tab'
                : 'custom-tab'
            }
            onClick={() => setActiveTab('Branding & Marketing Collateral')}
          >
            Branding & Marketing
          </div>
          <div
            className={activeTab == 'Custom logo Package' ? 'active-tab custom-tab' : 'custom-tab'}
            onClick={() => setActiveTab('Custom logo Package')}
          >
            Custom logo Package
          </div>
          <div
            className={activeTab == '2D & 3D Illustration' ? 'active-tab custom-tab' : 'custom-tab'}
            onClick={() => setActiveTab('2D & 3D Illustration')}
          >
            2D & 3D Illustration{' '}
          </div>

          {/* <div
            className={activeTab == 'Social Media' ? 'active-tab custom-tab' : 'custom-tab'}
            onClick={() => setActiveTab('Social Media')}
          >
            Social Media
          </div> */}
          {/* <div
            className={activeTab == 'Content Writing' ? 'active-tab custom-tab' : 'custom-tab'}
            onClick={() => setActiveTab('Content Writing')}
          >
            Content Writing
          </div> */}
        </div>
      </div>

      {/* {activeTab == 'Social Media' || activeTab == 'SEO' ? (
        <div className='tabs-cotainer'>
          <div className='top'>
            <div
              className={activeTab == 'Social Media' ? 'active-tab custom-tab' : 'custom-tab'}
              onClick={() => setActiveTab('Social Media')}
            >
              Social Media
            </div>
            <div
              className={activeTab == 'SEO' ? 'active-tab custom-tab' : 'custom-tab'}
              onClick={() => setActiveTab('SEO')}
            >
              SEO
            </div>
          </div>
          <div></div>
        </div>
      ) : null} */}
      <Row gutter={[20, 30]} justify='space-between' align='middle' style={{ width: '100%' }}>
        {tabData?.map((item, index) => (
          <Col key={index} xs={24} md={12} lg={8}>
            <PriceCard
              popular={item?.popular ? Image1 : ''}
              title={item?.title}
              subTitle={item?.subTitle}
              falsePrice={item?.falsePrice}
              price={item?.price}
              text={item?.text}
            />
          </Col>
        ))}
        {/* <Col xs={24} md={12} lg={8}>
          <PriceCard
            popular=''
            title='BEGINNERS LOGO'
            subTitle='PACKAGE'
            falsePrice='120'
            price='35'
            text={[
              '5 Custom Logo Design Concepts',
              'FREE Icon',
              'FREE Grayscale Formats',
              'FREE Color Options',
              '6 Revision Rounds',
              'All Final Files Format (JPEG, PNG, PDF, TIFF)',
              '24/48 Hours Turnaround Time',
              '100% Ownership Rights',
              '100% Satisfaction Guarantee',
              '100% Unique Design Guarantee (No Template)',
              '100% Money Back Guarantee *',
              'Turn around time 24 hours',
            ]}
          />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <PriceCard
            popular={Image1}
            title='SILVER LOGO'
            subTitle='PACKAGE'
            falsePrice='150'
            price='45'
            text={[
              '8 Custom Logo Design Concepts',
              '4 Dedicated Designers',
              'Unlimited Revisions',
              'FREE Icon',
              'FREE Custom Stationery Design (Letterhead, Envelope, Business Card)',
              'FREE MS Word Letterhead',
              'FREE Grayscale Formats',
              'FREE Color Options',
              'FREE File Formats (JPEG, PNG, PDF, PSD, EPS, TIFF)',
              '24/48 Hours Turnaround Time',
              '100% Ownership Rights',
              '100% Satisfaction Guarantee',
              '100% Unique Design Guarantee',
              '100% Money Back Guarantee *',
              'Turn around time 24 hours',
            ]}
          />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <PriceCard
            popular=''
            title='MOBILE APPS'
            subTitle='BASIC PACKAGE'
            falsePrice='2560'
            price='499'
            text={[
              '50% Upfront to get started with the work immediately',
              'No. of Features Up to 7',
              'Wireframing',
              'Intuitive UI UX (Custom App Design)',
              'Social Media Integration',
              'App Testing',
              'Publishing on App Store',
              'Paid bug support ($350/m)',
            ]}
          />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <PriceCard
            popular=''
            title='MOBILE APPS'
            subTitle='STANDARD PACKAGE'
            falsePrice='4160'
            price='2050'
            text={[
              '50% Upfront to get started with the work immediately',
              'No. of Features Up to 10',
              'Wireframing',
              'Intuitive UI UX (Custom App Design)',
              'Social Media Integration',
              'App Testing',
              'Ads Network Integration',
              'Firebase Integration',
              'In-App Purchase',
              'Publishing on App Store',
              '1 Month free bug support',
              'Native iOS OR Android app',
              'Cross-Platform (Hybrid) On Demand',
              'Push Notifications',
              'Messaging API Integration (Live Chat)',
              'Google Maps Integration',
              'Web APIs and Online Database',
              'CrashAnalytics Integration',
            ]}
          />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <PriceCard
            popular=''
            title='MOBILE APPS'
            subTitle='BUSINESS PACKAGE'
            falsePrice='10000'
            price='5000'
            text={[
              '25% Upfront to get started with the work immediately',
              'No. of Features Up to 25',
              'Wireframing',
              'Intuitive UI UX (Custom App Design)',
              'Social Media Integration',
              'App Testing',
              'Ads Network Integration',
              'Firebase Integration',
              'In-App Purchase',
              'Publishing on App Store',
              'App Store Optimization',
              '3 Month free bug support',
              'Native iOS OR Android app',
              'Cross-Platform (Hybrid) On Demand',
              'Push Notifications',
              'Messaging API Integration (Live Chat)',
              'Regular App Updates Yearly 1 Update',
              'Google Maps Integration',
              'Admin Panel',
              'Data Import/Export',
              'Web APIs and Online Database',
              'Picture Gallery/Product Display/Showcase Services On Demand',
              'Product Categories/Sub Categories',
              'CrashAnalytics Integration',
              'Audio/Video Streaming',
              'Payment Gateways Integration',
              'Shopping Cart',
              '3rd Party APIs Integrations',
            ]}
          />
        </Col>
        <Col xs={24} md={24} lg={24}>
          <ComboPackageCard />
        </Col> */}
      </Row>
      <div className='card-div'></div>
    </div>
  )
}

export default Prices
