import { Row, Col } from 'antd'
import Image1 from '../assets/process1.png';
import Image2 from '../assets/process2.png';
import Image3 from '../assets/process3.png';
import Image4 from '../assets/process4.png';

const Process = () => {
    return (
        <div className='process-container'>
            <h2>OUR PROCESS</h2>
            <p>Our order process comprises of 4 easy & simple steps that will save you a great deal of time.</p>
            <Row gutter={[40, 20]}>
                <Col xs={24} md={12} lg={6}>
                    <div className='single-process'>
                        <div className='process-thumb'>
                            <img src={Image1} />
                        </div>
                        <div className='body'>
                            <h6>Design Brief</h6>
                            <p>Client chooses one of the logo design packages available. Then the client explains the details and concept of a project. Client will be asked about his industry, his ideas, theme and key colors to be used.</p>
                        </div>
                    </div>
                </Col>
                <Col xs={24} md={12} lg={6}>
                    <div className='single-process'>
                        <div className='process-thumb'>
                            <img src={Image2} />
                        </div>
                        <div className='body'>
                            <h6>Conceptualization</h6>
                            <p>Brainstorming sessions and research is conducted to come up with unique concepts. Multiple concepts according to the selected package are made at this point.</p>
                        </div>
                    </div>
                </Col>
                <Col xs={24} md={12} lg={6}>
                    <div className='single-process'>
                        <div className='process-thumb '>
                            <img src={Image3} />
                        </div>
                        <div className='body'>
                            <h6>First Draft & Revision</h6>
                            <p>The initial concepts are created and shared with the client for review. The client selects a design and/or provides feedback.</p>
                        </div>
                    </div>
                </Col>
                <Col xs={24} md={12} lg={6}>
                    <div className='single-process'>
                        <div className='process-thumb'>
                            <img src={Image4} />
                        </div>
                        <div className='body'>
                            <h6>Final Delivery</h6>
                            <p>After going through all the stages and feedbacks, the design is finally launched and/or delivered as per the requirement.</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Process;