import { BsCheck, BsFillTelephoneFill } from 'react-icons/bs'
import { Button } from 'antd'
import { FaComments } from 'react-icons/fa'
import { useState } from 'react'
import FormModal from './modal/FormModal'

const PriceCard = ({ popular, title, subTitle, price, text }) => {
  const [open, setOpen] = useState(false)

  return (
    <div className='price-card'>
      <div className='head'>
        <img src={popular} />
        <h3>
          {title}
          <br />
          {subTitle}
        </h3>
        <div className='flex'>
          {/* <p>${falsePrice}</p> */}
          <h3>${price}</h3>
        </div>
      </div>
      <div className='content'>
        {text?.map((item, i) => {
          return (
            <div className='text' key={i}>
              <BsCheck />
              <p>{item}</p>
            </div>
          )
        })}
      </div>
      <div className='btn-container'>
        <Button href='tel:+13477163737'>
          <BsFillTelephoneFill />
          Call Us
        </Button>
        <Button
          target='_blank'
          type='link'
          href='https://api.whatsapp.com/send/?phone=966569478992&text&type=phone_number&app_absent=0'
        >
          <FaComments />
          Chat Now
        </Button>
      </div>
      <div
        className='order'
        onClick={() => {
          setOpen(true)
        }}
      >
        <p>ORDER NOW</p>
      </div>
      <FormModal open={open} setOpen={setOpen} title={title} price={price} />
    </div>
  )
}

export default PriceCard
