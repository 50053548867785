import { Row, Col, Form, Input, Button } from 'antd'
import { FaUserAlt, FaComments } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'
import { BsFillTelephoneFill } from 'react-icons/bs'
import Image1 from '../assets/sl1.png'
import Image2 from '../assets/sl2.png'

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { getInTouch } from '../redux/actions/formAction'

const contentStyle = {};
const Banner = () => {
    const [form] = Form.useForm()
    const responsive = {
        mobile: {
            breakpoint: { max: 4000, min: 0 },
            items: 1
        }
    };

    const onFinish = (values) => {
        console.log(values)
        getInTouch({
            ...values,
            subject: "70% OFF – LIMITED TIME DISCOUNT OFFER"
        }, () => { }, form)
    }
    return (
        <div className="banner-container">
            <Row gutter={[0, 40]} justify='space-between' align='middle'>
                <Col xs={24} xl={11}>
                    <div className='banner-content'>
                        <div className='headings'>
                            <h3>AFFORDABLE DECOTECHS LOGO SERVICE</h3>
                            <h2>BY AWARD WINNING LOGO DESIGNERS</h2>
                            <p>Your Custom Logo Design by Professional Logo Design Agency is Just a Click Away!</p>
                        </div>
                        <div className='form'>
                            <h2>PRICE STARTING FROM $25</h2>
                            <p>70% OFF – LIMITED TIME DISCOUNT OFFER</p>
                            <Form
                                form={form}
                                className='login-form'
                                onFinish={onFinish}
                                autoComplete='off'
                            >
                                <Row gutter={[20, 0]}>
                                    <Col xs={24} md={12} lg={12}>
                                        <Form.Item name='name'
                                            rules={[
                                                {
                                                    required: true,

                                                },

                                            ]}
                                            requiredMark={"optional"}
                                        >
                                            <div>
                                                <Input
                                                    type='text'
                                                    placeholder="Name" prefix={<FaUserAlt />}
                                                />
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12} lg={12}>
                                        <Form.Item name='phone'
                                            rules={[
                                                {
                                                    required: true,

                                                },

                                            ]}
                                            requiredMark={"optional"}
                                        >
                                            <div>
                                                <Input
                                                    type='number'
                                                    style={{ width: "100%" }}
                                                    placeholder="Phone Number" prefix={<BsFillTelephoneFill />}
                                                />
                                            </div>
                                        </Form.Item>
                                    </Col>


                                    <Col xs={24} md={12} lg={12}>

                                        <Form.Item name='email'
                                            rules={[
                                                {
                                                    required: true,

                                                },

                                            ]}
                                            requiredMark={"optional"}
                                        >
                                            <div>
                                                <Input
                                                    type='email'
                                                    placeholder="Email" prefix={<MdEmail />}
                                                />
                                            </div>
                                        </Form.Item>

                                    </Col>
                                    <Col xs={24} md={12} lg={12}>

                                        <Form.Item name='message'
                                            rules={[
                                                {
                                                    required: true,

                                                },

                                            ]}
                                            requiredMark={"optional"}
                                        >
                                            <div>
                                                <Input
                                                    type='text'
                                                    prefix={<FaComments />}
                                                    placeholder="Message"
                                                />
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <div className='form-btn'>
                                        <Button
                                            htmlType='submit'
                                        >Get Started</Button>

                                    </div>
                                </Row>
                            </Form>
                        </div>
                        <div className='caution'>
                            <p>Any Confusion? <span> Why not discuss your idea? </span></p>
                        </div>

                    </div>

                </Col>
                <Col xs={24} md={24} xl={12}>
                    <div className='slider'>
                        <div className='slider2'>
                            <Carousel
                                // showDots
                                containerClass='carousel'
                                arrows={false}
                                autoPlay
                                autoPlaySpeed={2000}
                                infinite
                                responsive={responsive}>
                                <img src={Image1} style={contentStyle} />
                                <img src={Image2} style={contentStyle} />
                            </Carousel>
                        </div>
                    </div>
                </Col>
            </Row>

        </div>
    )
}

export default Banner;