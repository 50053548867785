import { Row, Col, Button } from 'antd';
import Image1 from '../assets/phone-icon.png';
import Image2 from '../assets/chat-icon.png';
import FormModal from './modal/FormModal';
import { useState } from 'react'

const Questions = () => {
    const [open, setOpen] = useState(false)
    return (
      <div className='questions'>
        <Row>
          <Col xs={24}>
            <h5>HAVE ANY QUESTION?</h5>
          </Col>
          <Col xs={24} md={12} lg={8} xl={9}>
            <div className='we-are-here'>
              <h3>WE ARE HERE 24X7!</h3>
            </div>
          </Col>
          <Col xs={24} md={12} lg={7} xl={5}>
            <a className='call-flex' style={{ cursor: 'pointer' }} href='tel:+13477163737'>
              <div className='bg-img-call'>
                <img src={Image1} />
              </div>
              <div className='call-info'>
                <p>CALL</p>
                <h6>+</h6>
              </div>
            </a>
          </Col>
          <Col xs={24} md={12} lg={7} xl={5}>
            <div
              className='chat-flex'
              style={{ cursor: 'pointer' }}
              onClick={() =>
                window.open(
                  'https://api.whatsapp.com/send/?phone=966569478992&text&type=phone_number&app_absent=0',
                )
              }
            >
              <div className='bg-img-chat'>
                <img src={Image2} />
              </div>
              <div className='chat-info'>
                <p>NEED HELP</p>
                <h6>LIVE CHAT</h6>
              </div>
            </div>
          </Col>
          <Col xs={24} md={12} lg={24} xl={4}>
            <div className='btn-container'>
              <Button
                onClick={() => {
                  setOpen(true)
                }}
              >
                REQUEST A CALL BACK
              </Button>
            </div>
          </Col>
        </Row>
        <FormModal open={open} setOpen={setOpen} title='Requesta Call Back' price='' />
      </div>
    )
}

export default Questions;