import { Row, Col, Input, Button, Form } from 'antd'
import { FaUserAlt, FaLinkedinIn, FaFacebookF } from 'react-icons/fa'
// import { EditFilled } from '@ant-design/icons'
import { MdEmail } from 'react-icons/md'
import { BsFillTelephoneFill } from 'react-icons/bs'
import { AiFillInstagram } from 'react-icons/ai'
import Image1 from '../assets/payment.png'
import { getInTouch } from '../redux/actions/formAction'
import Clutch from '../assets/clutch.png';
import Trust from '../assets/trustPilot.png'

const FooterTop = () => {
    const { TextArea } = Input;
    const [form] = Form.useForm()
    const onFinish = (values) => {
        console.log(values)
        getInTouch({
            ...values,
            subject: "THE PERFECT DIRECTION?"
        }, () => { }, form)
    }

    return (
        <div className="footer-top">
            <Row gutter={[30, 20]} justify='space-between'>
                <Col xs={24}>
                    <div className='head'>
                        <h3>READY TO GIVE YOUR BUSINESS</h3>
                        <h2>THE PERFECT DIRECTION?</h2>
                    </div>
                </Col>
                <Col xs={24} lg={14}>
                    <Form
                        form={form}
                        className='footer-form'
                        wrapperCol={{
                            span: 24,
                        }}
                        onFinish={onFinish}
                        autoComplete='off'
                    >
                        <div >

                        </div>
                        <Form.Item name='name'
                            rules={[
                                {
                                    required: true,

                                },

                            ]}
                            requiredMark={"optional"}
                        >
                            <div>
                                <Input
                                    type='text'
                                    style={{ width: "100%" }}
                                    placeholder="Name" prefix={<FaUserAlt />}
                                />
                            </div>
                        </Form.Item>
                        <Form.Item name='phone'
                            rules={[
                                {
                                    required: true,

                                },

                            ]}
                            requiredMark={"optional"}
                        >
                            <div>
                                <Input
                                    type='number'
                                    style={{ width: "100%" }}
                                    placeholder="Phone" prefix={<BsFillTelephoneFill />}
                                />
                            </div>
                        </Form.Item>

                        <Form.Item name='email'
                            rules={[
                                {
                                    required: true,

                                },

                            ]}
                            requiredMark={"optional"}
                        >
                            <div>
                                <Input
                                    type='email'
                                    style={{ width: "100%" }}
                                    placeholder="Email" prefix={<MdEmail />}
                                />
                            </div>
                        </Form.Item>

                        <Form.Item name='message'
                            rules={[
                                {
                                    required: true,

                                },

                            ]}
                            requiredMark={"optional"}
                        >
                            <div>
                                <TextArea rows={2}
                                    style={{ width: "100%" }}
                                    // prefix={<EditFilled />}
                                    placeholder="Name on the Logo and Design Preferences" />
                            </div>
                        </Form.Item>
                        <div className='form-btn'>
                            <Button
                                htmlType='submit'
                            >SEND</Button>
                        </div>
                    </Form>
                </Col>
                <Col xs={24} lg={8}>
                    <div className='socialize'>
                        <h4>GET SOCIALIZE</h4>
                    </div>
                    <div className='icons-div border-bottom'>
                        <FaFacebookF onClick={() => window.open('https://www.facebook.com/Decotechsdotcom')} />
                        {/* <BsTwitter onClick={() => window.open('https://www.instagram.com/decotechs/')} /> */}
                        <FaLinkedinIn onClick={() => window.open("https://www.linkedin.com/company/decotechs")} />
                        <AiFillInstagram onClick={() => window.open('https://www.instagram.com/decotechs')} />
                    </div>
                    <div className='border-bottom'>
                        <h4>ONLINE CUSTOMER SERVICE</h4>
                    </div>
                    <div className='payment'>
                        <h4>EASY PAYMENT</h4>
                    </div>
                    <div className='cards-img'>
                        <img src={Image1} />
                    </div>
                    <div className='trusted'>
                        <img src={Clutch} />
                        <img src={Trust} />
                    </div>
                </Col>
                {/* <Col xs={24} lg={24}>
                    
                </Col> */}
            </Row>
        </div>
    )
}

export default FooterTop;