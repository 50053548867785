export const WebsiteDesign = [
  {
    popular: false,
    title: 'Basic Packages',
    subTitle: '',
    falsePrice: '',
    price: '219.00 USD',
    text: [
      '2 Stock Images',
      '3 Page Website',
      '1 jQuery Slider Banner',
      'Contact/Query Form',
      'Complete W3C Certified HTML',
      '48 to 72 hours TAT',
      'Complete Deployment',
      '100% Satisfaction Guarantee',
      '100% Unique Design Guarantee',
      '100% Money Back Guarantee *',
    ],
  },
  {
    popular: false,
    title: 'Startup Packages',
    subTitle: '',
    falsePrice: '',
    price: '399.00 USD',
    text: [
      '5 Stock Photos',
      '5 Page Website',
      '3 Banner Design',
      '1 jQuery Slider Banner',
      'FREE Google Friendly Sitemap',
      'Complete W3C Certified HTML',
      '48 to 72 hours TAT',
      '100% Satisfaction Guarantee',
      '100% Unique Design Guarantee',
      '100% Money Back Guarantee *',
      'Mobile Responsive will be Additional $200*',
      'CMS will be Additional',
    ],
  },
  {
    popular: true,
    title: 'Professional Packages',
    subTitle: '',
    falsePrice: '',
    price: '819.00 USD',
    text: [
      '10 Unique Pages Website',
      'CMS / Admin Panel Support',
      '8 Stock images',
      '5 Banner Designs',
      '1 jQuery Slider Banner',
      'FREE Google Friendly Sitemap',
      'Complete W3C Certified HTML',
      '48 to 72 hours TAT',
      'Complete Deployment',
      '100% Satisfaction Guarantee',
      '100% Unique Design Guarantee',
      '100% Money Back Guarantee *',
      'Mobile Responsive will be Additional',
    ],
  },
  {
    popular: false,
    title: 'Identity Packages',
    subTitle: '',
    falsePrice: '',
    price: '1399.00 USD',
    text: [
      'Upto 15 Unique Pages Website',
      'Conceptual and Dynamic Website',
      'Mobile Responsive',
      'Online Reservation/Appointment Tool (Optional)',
      'Online Payment Integration (Optional)',
      'Custom Forms',
      'Lead Capturing Forms (Optional)',
      'Striking Hover Effects',
      'Newsletter Subscription (Optional)',
      'Newsfeed Integration',
      'Social Media Integration',
      'Search Engine Submission',
      '5 Stock Photos',
      '3 Unique Banner Design',
      '1 jQuery Slider Banner',
      'Complete W3C Certified HTML',
      '48 to 72 hours TAT',
      'Complete Deployment',
      '100% Satisfaction Guarantee',
      '100% Unique Design Guarantee',
      '100% Money Back Guarantee *',
    ],
  },
  {
    popular: false,
    title: 'Elite Packages',
    subTitle: '',
    falsePrice: '',
    price: '1799.00 USD',
    text: [
      '15 to 20 Pages Website',
      'Custom Made, Interactive, Dynamic & High End Design',
      'Custom WP (or) Custom PHP Development',
      '1 jQuery Slider Banner',
      'Up to 10 Custom Made Banner Designs',
      '10 Stock Images',
      'Unlimited Revisions',
      'Special Hoover Effects',
      'Content Management System (CMS)',
      'Online Appointment/Scheduling/Online Ordering Integration (Optional)',
      'Online Payment Integration (Optional)',
      'Multi Lingual (Optional)',
      'Custom Dynamic Forms (Optional)',
      'Signup Area (For Newsletters, Offers etc.)',
      'Search Bar',
      'Live Feeds of Social Networks integration (Optional)',
      'Mobile Responsive',
      'FREE 5 Years Domain Name',
      'Free Google Friendly Sitemap',
      'Search Engine Submission',
      'Complete W3C Certified HTML',
      'Industry Specified Team of Expert Designers and Developers',
      'Complete Deployment',
      'Dedicated Accounts Manager',
      '100% Ownership Rights',
      '100% Satisfaction Guarantee',
      '100% Unique Design Guarantee',
      '100% Money Back Guarantee *',
    ],
  },
  {
    popular: true,
    title: 'Business Packages',
    subTitle: '',
    falsePrice: '',
    price: '2999.00 USD',
    text: [
      '15 Seconds 2D Explainer Video',
      'Voice - Over & Sound Effects',
      'Professional Script Writing',
      'Storyboard',
      'SEO Meta Tags',
      '15 to 20 Pages Website',
      'Custom Made, Interactive, Dynamic & High End Design',
      'Custom WP (or) Custom PHP Development',
      '1 jQuery Slider Banner',
      'Up to 10 Custom Made Banner Designs',
      '10 Stock Images',
      'Unlimited Revisions',
      'Special Hoover Effects',
      'Content Management System (CMS)',
      'Online Appointment/Scheduling/Online Ordering Integration (Optional)',
      'Online Payment Integration (Optional)',
      'Multi Lingual (Optional)',
      'Custom Dynamic Forms (Optional)',
      'Signup Area (For Newsletters, Offers etc.)',
      'Search Bar',
      'Live Feeds of Social Networks integration (Optional)',
      'Mobile Responsive',
      'FREE 5 Years Domain Name',
      'Free Google Friendly Sitemap',
      'Search Engine Submission',
      'Complete W3C Certified HTML',
      'Industry Specified Team of Expert Designers and Developers',
      'Complete Deployment',
      'Dedicated Accounts Manager',
      '100% Ownership Rights',
      '100% Satisfaction Guarantee',
      '100% Unique Design Guarantee',
      '100% Money Back Guarantee *',
    ],
  },
  {
    popular: false,
    title: 'Web Package',
    subTitle: 'CUSTOMIZED WEB PORTAL',
    falsePrice: '',
    price: '4999.99',
    text: [
      'Complete Custom Design & Development',
      'Dating Portal, Job Portal, Professional Network Portal, Social Network Portal, Restaurant Portal, Medical Portal, Enterprise Portal (Any One)',
      'Unique, User Friendly, Interactive, Dynamic, High End UI Design',
      'Unlimited Banner Designs',
      'Interactive Sliding Banners',
      'Special Hover Effects',
      'Unlimited Stock Images',
      'User Signup Area',
      'Client/User Dashboard Area',
      'Custom Coding and Development',
      'Content Management System (Custom)',
      'Online Appointment/Scheduling/Online Ordering Integration (Optional)',
      'Online Payment Integration (Optional)',
      'Multi Lingual (Optional)',
      'Custom Dynamic Forms (Optional)',
      'Shopping Cart Integration (Optional)',
      'Complete Database Creation',
      'Signup Automated Email Authentication',
      'Web Traffic Analytics Integration',
      '3rd Party API Integrations',
      'Signup Area (For Newsletters, Offers etc.)',
      'Search Bar for Easy Search',
      'Live Feeds of Social Networks integration (Optional)',
      'Search Engine Submission',
      'Module-wise Architecture',
      'Extensive Admin Panel',
      'Award Winning Team of Expert Designers and Developers',
      'Complete Deployment',
      'Value Added Services',
      'Complete Source Files',
      'Dedicated Project Manager',
      '100% Ownership Rights',
      '100% Satisfaction Guarantee',
      '100% Money Back Guarantee',
    ],
  },
]

export const ECommerce = [
  {
    popular: true,
    title: 'E-Commerce',
    subTitle: 'Startup',
    falsePrice: '',
    price: '794.00 USD',
    text: [
      'Up-to 100 Products',
      'Content Management System (CMS)',
      'Mini Shopping Cart Integration',
      'Payment Module Integration',
      'Easy Product Search',
      'Dedicated designer & developer',
      'Unlimited Revisions',
      '100% Satisfaction Guarantee',
      '100% Unique Design Guarantee',
      '100% Money Back Guarantee *',
    ],
  },
  {
    popular: true,
    title: 'E-Commerce',
    subTitle: 'Professional',
    falsePrice: '',
    price: '1392.00 USD',
    text: [
      'Customized Design',
      'Up-to 500 Products',
      'Content Management System (CMS)',
      'Full Shopping Cart Integration',
      'Payment Module Integration',
      'Easy Product Search',
      'Product Reviews',
      '5 Promotional Banners',
      'Team of Expert Designers & Developers',
      'Unlimited Revisions',
      '100% Satisfaction Guarantee',
      '100% Unique Design Guarantee',
      '100% Money Back Guarantee *',
    ],
  },
  {
    popular: true,
    title: 'E-Commerce',
    subTitle: 'Elite',
    falsePrice: '',
    price: '3694.00 USD',
    text: [
      'UNLIMITED Logo Design Concepts',
      'By 6 Award Winning Designers',
      'Icon Design',
      'UNLIMITED Revisions',
      'Print Media',
      'Stationary Design (BusinessCard,Letterhead & Envelope)',
      'Invoice Design, Email Signature',
      'Bi-Fold Brochure (OR) 2 Sided Flyer Design',
      'Product Catalog Design',
      'Sign age Design (OR) Label Design',
      'T-Shirt Design (OR) Car Wrap Design',
      'Website',
      'E-Commerce Store Design',
      'Product Detail Page Design',
      'Unique Banner Slider',
      'Featured Products Showcase',
      'Full Shopping Cart Integration',
      'Unlimited Products',
      'Unlimited Categories',
      'Product Rating & Reviews',
      'Easy Product Search',
      'Payment Gateway Integration',
      'Multi-currency Support',
      'Content Management System',
      'Cutomer Log-in Area',
      'Mobile Responsive',
      'Social Media Plugins Integration',
      'Tell a Friend Feature',
      'Social Media Pages',
      'Facebook , Twitter, YouTube, Google+ & Pinterest Page Designs',
      'Value Added Services',
      'Dedicated Account Manager',
      'Unlimited Revisions',
      'All Final File Formats',
      '100% Ownership Rights',
      '100% Satisfaction Guarantee',
      '100% Unique Design Guarantee',
      '100% Money Back Guarantee *',
    ],
  },
  {
    popular: true,
    title: 'ADVANCED',
    subTitle: 'ECOMMERCE PACKAGE',
    falsePrice: '',
    price: '5999.99 USD',
    text: [
      'Unlimited Page Website',
      'Custom Content Management System (CMS)',
      'Unique Pages and UI Design',
      'Complete Custom Development',
      'Process Automation Tools',
      'Newsfeed Integration',
      'Social Media Plugins Integration',
      'upto 40 Stock images',
      '10 Unique Banner Designs',
      'JQuery Slider',
      'Search Engine Submission',
      'FREE 5 Years Hosting',
      'Custom Email Addresses',
      'Social Media Page Designs (Facebook, Twitter, Instagram)',
      'Complete W3C Certified HTML',
      'Complete Deployment',
      '100% Satisfaction Guarantee',
      '100% Unique Design Guarantee',
      'Money Back Guarantee',
      'Suppliers Integration (API NEEDED)',
      'Shipper Integration (API NEEDED)',
      'Order management',
      'LOT numbers and expire date tracking',
      'Transfer stock between warehouses (If Warehouse - API NEEDED)',
      'Receive stock into a specific warehouse (If Warehouse - API NEEDED)',
      'Fulfill orders from a particular warehouse (If Warehouse - API NEEDED)',
      'Stock Management',
      'Actionable Insights',
      'Real- Time Visibility',
      'Inventory Opportunities',
      '-Speak to suppliers during trivial conversations.',
      'Set and send actions to suppliers regarding governance and compliance materials.',
      'Place purchasing requests.',
      'Research and answer internal questions regarding procurement functionalities or a supplier/supplier set.',
      'Receiving/filing/documentation of invoices and payments/order requests',
      'Machine Learning (ML) for Supply Chain Planning (SCP)',
      'Machine Learning for Warehouse Management',
      'Natural Language Processing (NLP) for Data Cleansing and Building Data Robustness',
      'Create beautiful, professional invoices & estimates in just a few seconds and then instantly email them as PDFs directly to your customers or prospects',
      'Automated Split invoicing',
      'Automated Combine invoices',
      'Invoice templates',
      'Scan inventory into your orders, generate barcodes for your documents, and search for inventory or documents by scanning barcodes.',
      'Have multiple warehouses, offices, or retail stores? No problem. Easily track where all your inventory is by organizing everything into locations and zones. Organize inventory items using custom attributes such as size, color, and location. View how many you have globally or at each location.',
      'With built-in CRM features you can keep track of all your customer accounts with ease. Add multiple contacts, private notes, and review their purchase history.',
      'Performance and analytics',
      'Customization of Personal Details',
      'Process management',
      'Sales Automation',
      'Team Collaboration',
      'Marketing Automation',
      'Security',
      'Integrations',
      'Mobile Notifications',
      'Sales Reports',
      'Trend Analytics',
      'Forecasting',
      'Territory Management',
      'Account Management',
      'Event Integration',
      'Advanced Data Security',
      'With integrated purchase orders, you can easily replenish your inventory levels by ordering more stock and even track when those new items will arrive.',
      'Partial orders fulfill',
      'Backordering',
      'Generate extremely detailed reports for your inventory, sales and services. -Filter your reports by date-range and category to see whats making you the most money.',
    ],
  },
]

export const SocialMedia = [
  {
    popular: false,
    title: 'Brand Booster',
    subTitle: '',
    falsePrice: '',
    price: '349.00 USD',
    text: [
      'Covers Two Social Media Platforms',
      'Over 2500 Likes, Followers & Engagements On Social Media',
      'Custom Brand Marketing Strategy Creation',
      'Indepth Competitor Review',
      'Content Plan Creation',
      '12 Custom Design Posts Per Month',
      'Community Management',
      'Custom Monthly Banner Design',
      'Existing Page & Accounts Optimization',
      'Traffic & Conversion Tracking Setup With Facebook Pixel & Google Analytics',
    ],
  },
  {
    popular: false,
    title: 'Leads Wizard',
    subTitle: '',
    falsePrice: '',
    price: '699.00 USD',
    text: [
      'Covers Three Social Media Platforms',
      'Over 5000 Likes, Followers & Engagements On Social Media',
      'Custom Brand Marketing Strategy Creation',
      'FREE Facebook Ad Credit Worth $200',
      'Lead Form Setup & Optimization',
      'Facebook & Instagram Shops Setup',
      'Facebook & Instagram Ads Setup',
      'LinkedIn Native Ads & Sponsored InMail Setup',
      'Social Media Pages Moderation',
      'Indepth Competitor Review',
      'Content Plan Creation',
      '16 Custom Design Posts Per Month',
      'Community Management',
      'Custom Monthly Banner Design',
      'Existing Page & Accounts Optimization',
      'Traffic & Conversion Tracking Setup With Facebook Pixel & Google Analytics',
    ],
  },
  {
    popular: true,
    title: 'Sales Nirvana',
    subTitle: '',
    falsePrice: '',
    price: '1200.00 USD',
    text: [
      'Covers Five Social Media Platforms',
      'Over 10,000 Likes, Followers & Engagements On Social Media',
      'Advanced Brand Marketing Strategy Creation',
      'FREE Facebook & Instagram Ad Credit Worth $200',
      'Lead Form Setup & Optimization',
      'Facebook & Instagram Shops Setup',
      'Facebook & Instagram Ads Setup',
      'LinkedIn Native Ads & Sponsored InMail Setup',
      'Social Media Pages Moderation',
      'Indepth Competitor Review',
      'Content Plan Creation',
      '16 Custom Design Posts Per Month',
      'Community Management',
      'Targeted AB Testing Ad Campaigns',
      'Custom Monthly Banner Design',
      'Existing Page & Accounts Optimization',
      'Traffic & Conversion Tracking Setup With Facebook Pixel & Google Analytics',
    ],
  },
  {
    popular: true,
    title: 'Venture Package',
    subTitle: '',
    falsePrice: '',
    price: '2000.00 USD',
    text: [
      'Covers Five Social Media Platforms',
      'Over 10,000 Likes, Followers & Engagements On Social Media',
      'Advanced Brand Marketing Strategy Creation',
      'FREE Facebook & Instagram Ad Credit Worth $200',
      'Lead Form Setup & Optimization',
      'Facebook & Instagram Shops Setup',
      'Facebook & Instagram Ads Setup',
      'LinkedIn Native Ads & Sponsored InMail Setup',
      'Social Media Pages Moderation',
      'Indepth Competitor Review',
      'Content Plan Creation',
      '20 Custom Design Posts Per Month',
      'Community Management',
      'Targeted AB Testing Ad Campaigns',
      'Custom Monthly Banner Design',
      'Existing Page & Accounts Optimization',
      'Traffic & Conversion Tracking Setup With Facebook Pixel & Google Analytics',
    ],
  },
]

export const SEO = [
  {
    popular: false,
    title: 'SEO Basic',
    subTitle: '',
    falsePrice: '',
    price: '999.00 USD',
    text: [
      'Prior Analysis',
      'Business Analysis',
      'Consumer Analysis',
      'Competitor Analysis',
      '15 Selected Keywords Targeting',
      '15 Pages Keyword Targeted',
      'Webpage Optimization',
      'Meta Tags Creation',
      'Keyword Optimization',
      'Image Optimization',
      'Inclusion of anchors',
      'Tracking & Analysis',
      'Google Analytics Installation',
      'Google Webmaster Installation',
      'Call To Action Plan',
      'Creation of Sitemaps',
      'Reporting',
      'Monthly Reporting',
      'Recommendation',
      'Email Support',
      'Phone Support',
      'Off Page Optimization',
      'Social Bookmarking',
      'Slide Share Marketing',
      "Forums/FAQ's",
      'Link Building',
      'Directory Submission',
      'Local Business Listings',
    ],
  },
  {
    popular: false,
    title: 'SEO Premium',
    subTitle: '',
    falsePrice: '',
    price: '1499.00 USD',
    text: [
      'Prior Analysis',
      'Business Analysis',
      'Consumer Analysis',
      'Competitor Analysis',
      '30 Selected Keywords Targeting',
      '30 Pages Keyword Targeted',
      'Webpage Optimization',
      'Meta Tags Creation',
      'Keyword Optimization',
      'Image Optimization',
      'Inclusion of anchors',
      'Tracking & Analysis',
      'Google Places Inclusions',
      'Google Analytics Installation',
      'Google Webmaster Installation',
      'Call To Action Plan',
      'Creation of Sitemaps',
      'Reporting',
      'Monthly Reporting',
      'Recommendation',
      'Email Support',
      'Phone Support',
      'Off Page Optimization',
      'Social Bookmarking',
      'Slide Share Marketing',
      "Forums/FAQ's",
      'Link Building',
      'Directory Submission',
      'Local Business Listings',
    ],
  },
  {
    popular: false,
    title: 'SEO Platinum',
    subTitle: '',
    falsePrice: '',
    price: '1999.00 USD',
    text: [
      'Prior Analysis',
      'Business Analysis',
      'Consumer Analysis',
      'Competitor Analysis',
      '70 Selected Keywords Targeting',
      '50 Pages Keyword Targeted',
      'Webpage Optimization',
      'Meta Tags Creation',
      'Keyword Optimization',
      'Image Optimization',
      'Inclusion of anchors Tags',
      'Inclusion of anchors Indexing Modifications',
      'Tracking & Analysis',
      'Google Places Inclusions',
      'Google Analytics Installation',
      'Google Webmaster Installation',
      'Call To Action Plan',
      'Creation of Sitemaps',
      'Reporting',
      'Monthly Reporting',
      'Recommendation',
      'Email Support',
      'Phone Support',
      'Off Page Optimization',
      'Social Bookmarking',
      'Slide Share Marketing',
      "Forums/FAQ's",
      'Link Building',
      'Directory Submission',
      'Local Business Listings',
      'Blog Content Creation',
      'Local SEO',
    ],
  },
]

export const VideoData = [
  {
    popular: false,
    title: '2D Basic Video',
    subTitle: '',
    falsePrice: '',
    price: '329.00 USD',
    text: [
      '30 Second Video',
      'Professional Script',
      'Custom Design',
      'Storyboard',
      'Custom Animation',
      'Voice - Over & Sound Effects',
      '4 weeks Delivery',
      '100% Ownership Rights',
      '100% Satisfaction Guarantee',
      '100% Money Back Guarantee',
      '100% Unique Design Guarantee',
    ],
  },
  {
    popular: false,
    title: '2D Startup Video',
    subTitle: '',
    falsePrice: '',
    price: '829.00 USD',
    text: [
      '60 Second Video',
      'Professional Script',
      'Custom Design',
      'Storyboard',
      'Custom Animation',
      'Voice - Over & Sound Effects',
      '5 weeks Delivery',
      '100% Ownership Rights',
      '100% Satisfaction Guarantee',
      '100% Money Back Guarantee',
      '100% Unique Design Guarantee',
    ],
  },
  {
    popular: false,
    title: '2D Professional Video',
    subTitle: '',
    falsePrice: '',
    price: '1329.00 USD',
    text: [
      '90 Second Video',
      'Professional Script',
      'Custom Design',
      'Storyboard',
      'Custom Animation',
      'Voice - Over & Sound Effects',
      '6 weeks Delivery',
      '100% Ownership Rights',
      '100% Satisfaction Guarantee',
      '100% Money Back Guarantee',
      '100% Unique Design Guarantee',
    ],
  },
  {
    popular: false,
    title: '2D Elite Video',
    subTitle: '',
    falsePrice: '',
    price: '2029.00 USD',
    text: [
      '120 Second Video',
      'Professional Script',
      'Custom Design',
      'Storyboard',
      'Custom Animation',
      'Voice - Over & Sound Effects',
      '100% Ownership Rights',
      '100% Satisfaction Guarantee',
      '100% Money Back Guarantee',
      '100% Unique Design Guarantee',
    ],
  },
  {
    popular: false,
    title: '2D Corporate Video',
    subTitle: '',
    falsePrice: '',
    price: '2729.00 USD',
    text: [
      '180 Second Video',
      'Professional Script',
      'Custom Design',
      'Storyboard',
      'Custom Animation',
      'Voice - Over & Sound Effects',
      '100% Ownership Rights',
      '100% Satisfaction Guarantee',
      '100% Money Back Guarantee',
      '100% Unique Design Guarantee',
    ],
  },
  {
    popular: false,
    title: '3D Basic Video',
    subTitle: '',
    falsePrice: '',
    price: '3229.00 USD',
    text: [
      '30 Second Video',
      'Professional Script',
      'Custom Design',
      'Storyboard',
      'Custom Animation',
      'Voice - Over & Sound Effects',
      '100% Ownership Rights',
      '100% Satisfaction Guarantee',
      '100% Money Back Guarantee',
      '100% Unique Design Guarantee',
    ],
  },
  {
    popular: false,
    title: '3D Startup Video',
    subTitle: '',
    falsePrice: '',
    price: '4459.00 USD',
    text: [
      '60 Second Video',
      'Professional Script',
      'Custom Design',
      'Storyboard',
      'Custom Animation',
      'Voice - Over & Sound Effects',
      '100% Ownership Rights',
      '100% Satisfaction Guarantee',
      '100% Money Back Guarantee',
      '100% Unique Design Guarantee',
    ],
  },
  {
    popular: false,
    title: '3D Professional Video',
    subTitle: '',
    falsePrice: '',
    price: '5459.00 USD',
    text: [
      '90 Second Video',
      'Professional Script',
      'Custom Design',
      'Storyboard',
      'Custom Animation',
      'Voice - Over & Sound Effects',
      '100% Ownership Rights',
      '100% Satisfaction Guarantee',
      '100% Money Back Guarantee',
      '100% Unique Design Guarantee',
    ],
  },
  {
    popular: false,
    title: '3D Elite Video',
    subTitle: '',
    falsePrice: '',
    price: '6659.00 USD',
    text: [
      '90 Second Video',
      'Professional Script',
      'Custom Design',
      'Storyboard',
      'Custom Animation',
      'Voice - Over & Sound Effects',
      '100% Ownership Rights',
      '100% Satisfaction Guarantee',
      '100% Money Back Guarantee',
      '100% Unique Design Guarantee',
    ],
  },
]

export const MarketingData = [
  {
    popular: false,
    title: 'Startup Collateral',
    subTitle: '',
    falsePrice: '',
    price: '99.00 USD',
    text: [
      '2 Stationery Design Set',
      'FREE Fax Template',
      'Print Ready Formats',
      'UNLIMITED Revisions',
      '100% Satisfaction Guarantee',
      '100% Money Back Guarantee *',
    ],
  },
  {
    popular: false,
    title: 'Collateral Classic',
    subTitle: '',
    falsePrice: '',
    price: '199.00 USD',
    text: [
      '2 Stationery Design Set',
      'UNLIMITED Revisions',
      'Flyer Design',
      'Brochure Design (Bi-fold/Tri-fold)',
      '100% Satisfaction Guarantee',
      '100% Money Back Guarantee *',
    ],
  },
  {
    popular: false,
    title: 'Premium Collateral',
    subTitle: '',
    falsePrice: '',
    price: '399.00 USD',
    text: [
      '2 Stationery Design Set',
      'Packaging Design',
      'UNLIMITED Revisions',
      'T-Shirt Design',
      '100% Satisfaction Guarantee',
      '100% Money Back Guarantee *',
    ],
  },
  {
    popular: false,
    title: 'Unlimited Collateral',
    subTitle: '',
    falsePrice: '',
    price: '499.00 USD',
    text: [
      '2 Stationery Design Set',
      'Menu Card Design',
      'T-Shirt Design',
      '1 Banner Design',
      '100% Satisfaction Guarantee',
      '100% Money Back Guarantee *',
    ],
  },
]

export const Logo = [
  {
    popular: false,
    title: 'Basic Logo',
    subTitle: '',
    falsePrice: '',
    price: '45.00 USD',
    text: [
      '3 Custom Logo Design Concepts',
      '1 Dedicated Designer',
      '4 Revisions',
      '24 hours TAT',
      '100% Unique Design Guarantee',
      '100% Satisfaction Guarantee',
      '100% Money Back Guarantee *',
      '100% Ownership Rights',
    ],
  },
  {
    popular: false,
    title: 'Startup Logo',
    subTitle: '',
    falsePrice: '',
    price: '69.00 USD',
    text: [
      '4 Custom Logo Design Concepts',
      'By 2 Designer',
      'Unlimited Revisions',
      '24 to 48 hours TAT',
      '100% Unique Design Guarantee',
      '100% Satisfaction Guarantee',
      '100% Money Back Guarantee *',
      '100% Ownership Rights',
    ],
  },
  {
    popular: false,
    title: 'Professional Logo',
    subTitle: '',
    falsePrice: '',
    price: '119.00 USD',
    text: [
      'Unlimited Logo Design Concepts',
      'By 4 Industry Based Designers',
      'UNLIMITED Revisions',
      'FREE MS Electronic Letterhead',
      'FREE Custom Stationery Design (Letterhead, Business Card, Envelope)',
      '24 to 48 hours TAT',
      'FREE File Formats (EPS, Ai, GIF, JPEG, PSD)',
      '100% Unique Design Guarantee',
      '100% Satisfaction Guarantee',
      '100% Money Back Guarantee *',
      '100% Ownership Rights',
    ],
  },
  {
    popular: false,
    title: 'Identity Logo',
    subTitle: '',
    falsePrice: '',
    price: '169.00 USD',
    text: [
      'Unlimited Logo Design Concepts by 8 Designers',
      'FREE Icon Design',
      'FREE Unlimited Revisions',
      'Turnaround time 2-3 business days',
      '1 Stationery Design Set (Business card, Letterhead, Envelope & Email Signature)',
      '100% Satisfaction Guarantee',
      '100% Money Back Guarantee *',
      '100% Ownership Rights',
    ],
  },
  {
    popular: false,
    title: 'Corporate Logo',
    subTitle: '',
    falsePrice: '',
    price: '219.00 USD',
    text: [
      'UNLIMITED Logo Design Concepts',
      'By 8 Award Winning Designers',
      'Free Icon Design',
      'FREE Custom Stationery Design (Letterhead, Business Card, Envelope, Invoice)',
      'Double Side Flyer (OR) Bi-Fold Brochure',
      'FREE MS Electronic Letterhead',
      'Email Signature Design',
      'UNLIMITED Revisions',
      '48 to 72 hours TAT',
      '100% Unique Design Guarantee',
      '100% Satisfaction Guarantee',
      '100% Money Back Guarantee *',
      '100% Ownership Rights',
    ],
  },
  {
    popular: false,
    title: 'Elite Logo',
    subTitle: '',
    falsePrice: '',
    price: '419.00 USD',
    text: [
      'UNLIMITED Logo Design Concepts',
      'By 8 Design Artist',
      'UNLIMITED Revisions',
      '2 Stationary Design Sets',
      'FREE MS Word Letterhead',
      'Free Email Signature',
      '3 Page Custom Website',
      'Mobile Responsive',
      '2 Stock Photos',
      '2 Banner Designs',
      'jQuery Slider',
      'All Final Files Format (AI, PSD, EPS, PNG, GIF, JPG, PDF)',
      '100% Unique Design Guarantee',
      '100% Satisfaction Guarantee',
      '100% Money Back Guarantee *',
      '100% Ownership Rights',
    ],
  },
]

export const Illustration = [
  {
    popular: false,
    title: 'Basic Illustrative',
    subTitle: '',
    falsePrice: '',
    price: '294.00 USD',
    text: [
      '3 Custom Logo Design Concepts',
      'By 2 Designers',
      'UNLIMITED Revisions',
      '48 to 72 hours TAT',
      'All Final Files Format (AI, PSD, EPS, PNG, GIF, JPG, PDF)',
      '100% Unique Design Guarantee',
      '100% Satisfaction Guarantee',
      '100% Money Back Guarantee *',
      '100% Ownership Rights',
    ],
  },
  {
    popular: false,
    title: 'Startup Illustrative',
    subTitle: '',
    falsePrice: '',
    price: '294.00 USD',
    text: [
      '4 Custom Logo Design Concepts',
      'By 3 Designers',
      '48 to 72 hours TAT',
      'UNLIMITED Revisions',
      'All Final Files Format (AI, PSD, EPS, PNG, GIF, JPG, PDF)',
      '100% Unique Design Guarantee',
      '100% Satisfaction Guarantee',
      '100% Money Back Guarantee *',
      '100% Ownership Rights',
    ],
  },
  {
    popular: false,
    title: 'Professional Illustrative',
    subTitle: '',
    falsePrice: '',
    price: '294.00 USD',
    text: [
      'UNLIMITED Logo Design Concepts',
      'By 4 Designers',
      'UNLIMITED Revisions',
      '24 to 48 hours TAT',
      '100% Unique Design Guarantee',
      '100% Satisfaction Guarantee',
      '100% Money Back Guarantee *',
      '100% Ownership Rights',
    ],
  },
  {
    popular: false,
    title: '3D Logo',
    subTitle: '',
    falsePrice: '',
    price: '294.00 USD',
    text: [
      '3 Unique 3D Logo Concepts',
      'Light Effects and VFX',
      'Fully Rendered',
      'Multiple 3D Angles',
      'By 3 Award Winning Designers',
      '72 hours Turnaround Time',
      'UNLIMITED Revisions',
      '100% Unique Design Guarantee',
      '100% Satisfaction Guarantee',
      '100% Money Back Guarantee *',
      '100% Ownership Rights',
    ],
  },
]

export const Writing = [
  {
    popular: false,
    title: 'Web Content Basic',
    subTitle: '',
    falsePrice: '',
    price: '129.00 USD',
    text: [
      '1 Page Professional Copywriting Service',
      'Creative & Well-Written by 1 Professional Copywriter',
      'Industry Specified Expert Copywriter',
      '300 Words Per Page',
      '5 Revisions',
      '3 to 4 Business Days Rotation',
      'According To Your Exact Requirements',
      'Proofing by our in-house experts',
      '100% Ownership Rights',
      '100% Original Content',
      '100% Approval Assurance',
      '30 Days Refund Warranty',
    ],
  },
  {
    popular: false,
    title: 'Web Content Starter',
    subTitle: '',
    falsePrice: '',
    price: '499.00 USD',
    text: [
      '5 Page Professional Copywriting Service',
      'Creative & Well-Written by 2 Professional Copywriter',
      'Industry Specified Expert Copywriter',
      '300 Words Per Page',
      '10 Revisions',
      '5 to 7 Business Days Rotation',
      'According To Your Exact Requirements',
      'Proofing by our in-house experts',
      'FREE Meta details - With each custom page, we will provide a catchy title, keywords and page description.',
      'SEO friendly - Your keyword(s) will be placed in the title, the first & last paragraphs and throughout the web copy in a natural and fluent manner',
      '100% Ownership Rights',
      '100% Original Content',
      '100% Approval Assurance',
      '30 Days Refund Warranty',
    ],
  },
  {
    popular: false,
    title: 'Web Content Professional',
    subTitle: '',
    falsePrice: '',
    price: '894.00 USD',
    text: [
      '10 Page Professional Copywriting Service',
      'Creative & Well-Written by 2 Professional Copywriter',
      'Industry Specified Expert Copywriter',
      '300 Words Per Page',
      'Unlimited Revisions',
      '7 to 10 Business Days Rotation',
      'According To Your Exact Requirements',
      'Proofing by our in-house experts',
      '100% Ownership Rights',
      '100% Original Content',
      '100% Approval Assurance',
      '30 Days Refund Warranty',
    ],
  },
  {
    popular: false,
    title: 'Web Content Identity',
    subTitle: '',
    falsePrice: '',
    price: '1849.00 USD',
    text: [
      'Up to 20 Pages Professional Copywriting Service',
      'Creative & Well-Written by 3 Professional Copywriter',
      'Industry Specified Expert Copywriter',
      '300 Words Per Page',
      'Unlimited Revisions',
      '7 to 10 Business Days Rotation',
      'According To Your Exact Requirements',
      'Proofing by our in-house experts',
      '100% Ownership Rights',
      '100% Original Content',
      '100% Approval Assurance',
      '30 Days Refund Warranty',
    ],
  },
  {
    popular: false,
    title: 'Article Writing',
    subTitle: '',
    falsePrice: '',
    price: '149.00 USD',
    text: [
      '1 Creative, Fresh & Well-Written Article',
      'By 1 Professional Copywriter',
      'Industry Specified Expert Copywriter',
      '400 Words Per Page',
      '3 to 5 Business Days Rotation',
      '5 Revisions',
      'According To Your Exact Requirements',
      'Proofing by our in-house experts',
      '100% Ownership Rights',
      '100% Original Content',
      '100% Approval Assurance',
      '30 Days Refund Warranty',
    ],
  },
  {
    popular: false,
    title: 'Blog Writing',
    subTitle: '',
    falsePrice: '',
    price: '129.00 USD',
    text: [
      '1 Creative, Fresh & Well-Written Article',
      'By 1 Professional Copywriter',
      'Industry Specified Expert Copywriter',
      '400 Words Per Page',
      '3 to 5 Business Days Rotation',
      '5 Revisions',
      'According To Your Exact Requirements',
      'Proofing by our in-house experts',
      '100% Ownership Rights',
      '100% Original Content',
      '100% Approval Assurance',
      '30 Days Refund Warranty',
    ],
  },
]
