
const Footer = () => {
    return (
        <div className="footer">
            <p>© Copyright 2022 Decotechs Logo . All Rights Reserved | Privacy Policy | Terms & Conditions</p>
            <p>Disclaimer:</p>
            <p>Disclaimer:
Logo, portfolio, images and content are sole property of Decotechs Logo . All third party company names, brand names, trademarks displayed on this website are the property of their respective owners. Furthermore, Decotechs Logo has no influence over the third party material that is being displayed on the website. Therefore, we are also not responsible for any resemblance with any other material on the web. These portfolios and case studies are actual but exemplary (for better understanding); the actual names, designs, functionality, content and stats/facts may differ from the actual projects. This is due to the strict NDA policy that Logo Designs Center adheres to.</p>
        </div>
    )
}

export default Footer;