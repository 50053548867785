import { Row, Col } from 'antd'
import Image1 from '../assets/award1.png';
import Image2 from '../assets/award2.png';
import Image3 from '../assets/award3.png';
import Image4 from '../assets/award4.png';
import Image5 from '../assets/award5.png';
import Image6 from '../assets/award6.png';



const Awards = () => {
    return (
        <div className="awards">
            <Row gutter={[20, 20]}>
                <Col xs={24}>
                    <h2>AWARDS & RECOGNITIONS</h2>
                    <p>We{"'"}ve been Honored & Recognized for our Outstanding Achievements in the Industry</p>
                </Col>
                <Col xs={24} md={12} lg={4}>
                    <div className='awards-img'>
                        <img src={Image1} />
                    </div>
                </Col>
                <Col xs={24} md={12} lg={4}>
                    <div className='awards-img'>
                        <img src={Image2} />
                    </div>
                </Col>
                <Col xs={24} md={12} lg={4}>
                    <div className='awards-img'>
                        <img src={Image3} />
                    </div>
                </Col>
                <Col xs={24} md={12} lg={4}>
                    <div className='awards-img'>
                        <img src={Image4} />
                    </div>
                </Col>
                <Col xs={24} md={12} lg={4}>
                    <div className='awards-img'>
                        <img src={Image5} />
                    </div>
                </Col>
                <Col xs={24} md={12} lg={4}>
                    <div className='awards-img'>
                        <img src={Image6} />
                    </div>
                </Col>
            </Row>

        </div>
    )
}

export default Awards;