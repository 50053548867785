import Header from '../layout/Header'
import Banner from '../components/Banner'
import Image1 from '../assets/brands.png'
import OurWork from '../components/OurWork'
import Image2 from '../assets/phone-icon.png'
import Image3 from '../assets/chat-icon.png'
import Prices from '../components/Prices'
import Process from '../components/Process'
import Questions from '../components/Questions'
import Industries from '../components/Industries'
import Awards from '../components/Awards'
import FooterTop from '../components/FooterTop'
import Footer from '../components/Footer'
import Clients from '../components/Clients'

const Home = () => {
  return (
    <div className='container'>
      <div className='introuction'>
        <Header />
        <Banner />
      </div>
      <div className='experience'>
        <div className='experience-div'>
          <div className='experience-text'>
            <p>
              100% Satisfaction Gurantee! Over 10 Years of Experience in the Online Design Industry
            </p>
          </div>
          <div className='shadow-sep'></div>
          <div className='experience-img'>
            <img src={Image1} />
          </div>
        </div>
      </div>
      <div className='glimpse-of-our-work'>
        <OurWork />
      </div>
      <div className='creative-logos'>
        <h3>LET THE WORLD SEE YOUR BUSINESS</h3>
        <h2>IN ACTION WITH CREATIVE LOGOS</h2>
        <div className='flex'>
          <a className='call' style={{ cursor: 'pointer' }} href='tel:+13477163737'>
            <img src={Image2} />
            <p>Call +1 (347) 7163737</p>
          </a>
          <div
            className='chat'
            style={{ cursor: 'pointer' }}
            onClick={() =>
              window.open(
                'https://api.whatsapp.com/send/?phone=0966569478992&text&type=phone_number&app_absent=0',
              )
            }
          >
            <img src={Image3} />
            <div>
              <p>Need Help</p>
              <h6>Live Chat</h6>
            </div>
          </div>
        </div>
      </div>
      <div className='logo-prices'>
        <Prices />
      </div>
      <div className='logo-process'>
        <Process />
      </div>
      <div className='logo-ask'>
        <Questions />
      </div>
      <div className='logo-belonging'>
        <Industries />
      </div>
      <div className='reviews'>
        <Clients />
      </div>
      <div className='logo-awards'>
        <Awards />
      </div>
      <div className='logo-footer-top'>
        <FooterTop />
      </div>
      <div className='footer-bootom'>
        <Footer />
      </div>
    </div>
  )
}

export default Home
