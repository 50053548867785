import axios from "axios";
import { notification } from 'antd'



export const getInTouch = async (payload,setOpen,form) => {
      try {
        const res = await axios.post(`https://backend.trackkrr.com/contact`, payload);
        if (res) {
            notification.success({
                description: res.data.message || "Succesfully Sent",
                duration: 2,

              })
              setOpen(false)
              form.resetFields()
        }
      } catch (err) {
        notification.error({
            message: err?.response?.data?.message || 'Server Error',
            duration: 3,
          })
      }
  };