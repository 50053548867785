import { Button, Row, Col } from "antd";
import OurWorkCards from "./OurWorkCards";
import Image1 from '../assets/m1.jpg'
import Image2 from '../assets/m2.jpg'
import Image3 from '../assets/m3.jpg'
import Image4 from '../assets/m4.jpg'
import Image5 from '../assets/m5.jpg'
import Image6 from '../assets/m6.jpg'
import Image7 from '../assets/m7.jpg'
import Image8 from '../assets/m8.jpg'
import Image9 from '../assets/m9.jpg'
import Image10 from '../assets/m10.jpg'
import Image11 from '../assets/m11.jpg'
import Image12 from '../assets/m12.jpg'
import Image13 from '../assets/m13.jpg'
import Image14 from '../assets/m14.jpg'
import Image15 from '../assets/m15.jpg'
import Image16 from '../assets/m16.jpg'
import Image17 from '../assets/a1.jpg'
import Image18 from '../assets/a2.jpg'
import Image19 from '../assets/a3.jpg'
import Image20 from '../assets/a4.jpg'
import Image21 from '../assets/a5.jpg'
import Image22 from '../assets/a6.jpg'
import Image23 from '../assets/a7.jpg'
import Image24 from '../assets/a8.jpg'
import Image25 from '../assets/il1.jpg'
import Image26 from '../assets/il2.jpg'
import Image27 from '../assets/il3.jpg'
import Image28 from '../assets/il4.jpg'
import Image29 from '../assets/il5.jpg'
import Image30 from '../assets/il6.jpg'
import Image31 from '../assets/il7.jpg'
import Image32 from '../assets/il8.jpg'
import Image33 from '../assets/il9.jpg'
import Image34 from '../assets/il10.jpg'
import Image35 from '../assets/il11.jpg'
import Image36 from '../assets/il12.jpg'
import Image37 from '../assets/il13.jpg'
import Image38 from '../assets/il14.jpg'
import Image39 from '../assets/il15.jpg'
import Image40 from '../assets/il16.jpg'
import Image41 from '../assets/f1.jpg'
import Image42 from '../assets/f2.jpg'
import Image43 from '../assets/f3.jpg'
import Image44 from '../assets/f4.jpg'
import Image45 from '../assets/f5.jpg'
import Image46 from '../assets/f6.jpg'
import Image47 from '../assets/f7.jpg'
import Image48 from '../assets/f8.jpg'
import Image49 from '../assets/i1.jpg'
import Image50 from '../assets/i2.jpg'
import Image51 from '../assets/i3.jpg'
import Image52 from '../assets/i4.jpg'
import Image53 from '../assets/i5.jpg'
import Image54 from '../assets/i6.jpg'
import Image55 from '../assets/i7.jpg'
import Image56 from '../assets/i8.jpg'

import { useState } from "react";

const OurWork = () => {
    const [mascot, setMascot] = useState(true);
    const [abstract, setAbstract] = useState(false);
    const [illustrative, setIllustrative] = useState(false);
    const [flat, setFlat] = useState(false);
    const [iconic, setIconic] = useState(false);

    const handleMascot = () => {
        setMascot(true)
        setAbstract(false)
        setIllustrative(false)
        setFlat(false)
        setIconic(false)
    }



    const handleAbstract = () => {
        setMascot(false)
        setAbstract(true)
        setIllustrative(false)
        setFlat(false)
        setIconic(false)
    }

    const handleIllustrative = () => {
        setMascot(false)
        setAbstract(false)
        setIllustrative(true)
        setFlat(false)
        setIconic(false)
    }
    const handleFlat = () => {
        setMascot(false)
        setAbstract(false)
        setIllustrative(false)
        setFlat(true)
        setIconic(false)
    }

    const handleIconic = () => {
        setMascot(false)
        setAbstract(false)
        setIllustrative(false)
        setFlat(false)
        setIconic(true)
    }
    return (
        <div className="our-work-container">
            <div className="header">
                <h2>A GLIMPSE OF OUR WORK</h2>
                <div className="btn-container">
                    <Button className={mascot ? "active-btn" : ""} onClick={handleMascot}>Mascot Design</Button>
                    <Button className={abstract ? "active-btn" : ""} onClick={handleAbstract}>Abstract Design</Button>
                    <Button className={illustrative ? "active-btn" : ""} onClick={handleIllustrative}>Illustrative Design</Button>
                    <Button className={flat ? "active-btn" : ""} onClick={handleFlat}>Flat Design</Button>
                    <Button className={iconic ? "active-btn" : ""} onClick={handleIconic}>Iconic Design</Button>
                </div>
            </div>
            <div className="mascot-design">
                <Row gutter={[20, 30]}>
                    {
                        mascot &&
                        <>

                            <Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image1} />
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image2} />
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image3} />
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image4} />
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image5} />
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image6} />
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image7} />
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image8} />
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image9} />
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image10} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image11} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image12} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image13} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image14} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image15} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image16} />
                            </Col>
                        </>
                    }

                    {
                        abstract &&
                        <>
                            <Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image17} />
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image18} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image19} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image20} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image21} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image22} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image23} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image24} />
                            </Col>
                        </>
                    }
                    {illustrative &&
                        <>
                            <Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image25} />
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image26} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image27} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image28} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image29} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image30} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image31} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image32} />
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image33} />
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image34} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image35} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image36} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image37} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image38} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image39} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image40} />
                            </Col>
                        </>
                    }
                    {flat &&
                        <>
                            <Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image41} />
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image42} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image43} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image44} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image45} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image46} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image47} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image48} />
                            </Col>
                        </>
                    }
                    {iconic &&
                        <>
                            <Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image49} />
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image50} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image51} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image52} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image53} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image54} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image55} />
                            </Col><Col xs={24} md={12} lg={6}>
                                <OurWorkCards Image={Image56} />
                            </Col>
                        </>
                    }

                </Row>

            </div>
        </div>
    )
}

export default OurWork;