import { Modal, Button, Form, Input } from 'antd'
import { getInTouch } from '../../redux/actions/formAction'
const FormModal = ({ open, setOpen, title, price }) => {

    const [form] = Form.useForm()
    const handleOk = () => {
        setOpen(false)
    }
    const handleCancel = () => {
        setOpen(false)
    }

    const onFinish = (values) => {
        getInTouch({
            ...values,
            subject: `Selected Package ${title} with price ${price}`
        }, setOpen, form)

    }
    return (
        <Modal
            footer={false}
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            maskClosable={true}
            closeIcon={<></>}
        >
            <Form
                form={form}
                className='order-form'
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                onFinish={onFinish}
                autoComplete='off'
            >
                <Form.Item label='Name' name='name'
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    requiredMark={"optional"}
                >
                    <Input />
                </Form.Item>
                <Form.Item label='Phone Number' name='phone'
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    requiredMark={"optional"}

                >
                    <Input type='number' />
                </Form.Item>
                <Form.Item label='Email' name='email'
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    requiredMark={"optional"}

                >
                    <Input type='email' />
                </Form.Item>
                <Form.Item label='Message' name='message'
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    requiredMark={"optional"}

                >
                    <Input />
                </Form.Item>
                <Form.Item>

                    <div className='btn-container'>
                        <Button
                            htmlType='submit'
                            type='primary'
                        >Send</Button>

                    </div>
                </Form.Item>
            </Form>


        </Modal>
    )

}

export default FormModal;