import ClientSays from "./ClientSays";
// import {Carousel} from 'antd'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Image1 from '../assets/testimonial-1.png';
import Image3 from '../assets/testimonial-2.png';
import Image4 from '../assets/testimonial-3.png';

const responsive = {
    mobile: {
        breakpoint: { max: 4000, min: 0 },
        items: 1
    }
};

const Clients = () => {
    return (
        <div className="clients">
            <div className="black_border" />
            <div className="clients-head">
                <h2>OUR CLIENT SAYS</h2>
            </div>
            <Carousel 
            showDots 
            arrows={false} 
            autoPlay 
            autoPlaySpeed={2000} 
            infinite 
            responsive={responsive}>
                <div className="clients-slider" >
                    <ClientSays Image= {Image1} review="Design should be the one, which explains the purpose in one sight and this is what we expected for. Decotechs Logo work is worth appreciating, really amazing work is done by you guys! This logo design gives a unique identity to our business also made it possible for us to attract more customers." name="Alex John"/>
                </div>
                <div className="clients-slider">
                    <ClientSays Image= {Image3} name="Saqib Ali" review="My friend referred Decotechs Logo to me and I thought of giving it a try. This was my first time that I purchased a design service online, before this I got a logo done through a local graphic designer and had to compromise on his terms., But Decotechs Logo managed to do an awesome job."/>
                </div>
                <div className="clients-slider">
                    <ClientSays Image= {Image4} name="Nancy Smith" review="logo.decotechs.com actually does beyond what they say; to satisfy their clients. The whole process was greatly executed; even in the end we got a perfect logo for our business. logo.decotechs.com made the whole process exciting and enjoyable. WELL DONE!!! Decotechs Logo are simply creative at logo designs." />
                </div>
            </Carousel>

        </div>
    )
}

export default Clients;