import Image2 from '../assets/test-arow.png';

const ClientSays = ({Image, review, name}) => {
    
    return (
        <div className="client-says">
            <div className='client-img'>
                <img src={Image} />
            </div>
            <div className='client-reviews'>
                <div className='quotation-img'>
                    <img src={Image2} />
                </div>
                <p>{review}</p>
                <h4>{name}</h4>
            </div>
        </div>
    )
}

export default ClientSays;