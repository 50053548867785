import { Row, Col } from 'antd'
import { FaComments } from 'react-icons/fa'
import { BsFillTelephoneFill } from 'react-icons/bs'
import Logo from '../assets/logoDecotechs.png'
const Header = () => {
    return (
      <div className='main-header'>
        <Row>
          <Col xs={24} md={24} lg={12}>
            <div className='logo'>
              <img src={Logo} />
            </div>
          </Col>
          <Col xs={24} md={24} lg={12}>
            <div className='contact-info'>
              <div
                className='chat-info'
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  window.open(
                    'https://api.whatsapp.com/send/?phone=966569478992&text&type=phone_number&app_absent=0',
                  )
                }
              >
                <FaComments />
                <p>Live chat with expert</p>
              </div>
              <div className='division'>
                <p>|</p>
              </div>
              <a className='call-info' style={{ cursor: 'pointer' }} href='tel:+13477163737'>
                <BsFillTelephoneFill />
                <p>Call: +1 (347) 7163737</p>
              </a>
            </div>
          </Col>
        </Row>
      </div>
    )
}

export default Header;