import { Row, Col } from 'antd'
import Image1 from '../assets/industry-1.png';
import Image2 from '../assets/industry-2.png';
import Image3 from '../assets/industry-3.png';
import Image4 from '../assets/industry-4.png';
import Image5 from '../assets/industry-5.png';
import Image6 from '../assets/industry-6.png';
import Image7 from '../assets/industry-7.png';
import Image8 from '../assets/industry-8.png';
import Image9 from '../assets/industry-9.png';
import Image10 from '../assets/industry-10.png';
import Image11 from '../assets/industry-11.png';
import Image12 from '../assets/industry-12.png';
import Image13 from '../assets/industry-13.png';
import Image14 from '../assets/industry-14.png';
import Image15 from '../assets/industry-15.png';
import Image16 from '../assets/industry-16.png';
import Image17 from '../assets/industry-17.png';
import Image18 from '../assets/industry-18.png';
import Image19 from '../assets/industry-19.png';
import Image20 from '../assets/industry-20.png';
import Image21 from '../assets/industry-21.png';
import Image22 from '../assets/industry-22.png';
import Image23 from '../assets/industry-23.png';
import Image24 from '../assets/industry-24.png';
import Image25 from '../assets/industry-25.png';
import Image26 from '../assets/industry-26.png';
import Image27 from '../assets/industry-27.png';
import Image28 from '../assets/industry-28.png';
import Image29 from '../assets/industry-29.png';
import Image30 from '../assets/industry-30.png';

const Industries = () => {
    return (
        <div className="industries">
            <Row gutter={[20, 20]} >
                <Col xs={24} md={24} lg={24}>
                    <div className="head">
                        <p>SERVING FORTUNE 500 COMPANIES</p>
                        <h2>BELONGING TO 45+ INDUSTRIES!</h2>
                    </div>
                </Col>
                <Col xs={12} md={8} lg={4}>
                    <div className='industries-flex'>
                        <img src={Image1} />
                        <h4>Engineering</h4>
                    </div>
                    <div className='industries-flex'>
                        <img src={Image2} />
                        <h4>Construction</h4>
                    </div>
                    <div className='industries-flex'>
                        <img src={Image3} />
                        <h4>Technology</h4>
                    </div>
                    <div className='industries-flex'>
                        <img src={Image4} />
                        <h4>Automotive</h4>
                    </div>
                    <div className='industries-flex'>
                        <img src={Image5} />
                        <h4>Catalogues</h4>
                    </div>

                </Col>
                <Col xs={12} md={8} lg={4}>
                    <div className='industries-flex'>
                        <img src={Image6} />
                        <h4>Religion</h4>
                    </div>
                    <div className='industries-flex'>
                        <img src={Image7} />
                        <h4>Social</h4>
                    </div>
                    <div className='industries-flex'>
                        <img src={Image8} />
                        <h4>Education</h4>
                    </div>
                    <div className='industries-flex'>
                        <img src={Image9} />
                        <h4>Human Resource</h4>
                    </div>
                    <div className='industries-flex'>
                        <img src={Image10} />
                        <h4>Sports</h4>
                    </div>
                </Col>
                <Col xs={12} md={8} lg={4}>
                    <div className='industries-flex'>
                        <img src={Image11} />
                        <h4>Financial</h4>
                    </div>
                    <div className='industries-flex'>
                        <img src={Image12} />
                        <h4>Insurance</h4>
                    </div>
                    <div className='industries-flex'>
                        <img src={Image13} />
                        <h4>Consultation</h4>
                    </div>
                    <div className='industries-flex'>
                        <img src={Image14} />
                        <h4>Architectural</h4>
                    </div>
                    <div className='industries-flex'>
                        <img src={Image15} />
                        <h4>Food</h4>
                    </div>
                </Col>
                <Col xs={12} md={8} lg={4}>
                    <div className='industries-flex'>
                        <img src={Image16} />
                        <h4>Medical</h4>
                    </div>
                    <div className='industries-flex'>
                        <img src={Image17} />
                        <h4>Health</h4>
                    </div>
                    <div className='industries-flex'>
                        <img src={Image18} />
                        <h4>Travel</h4>
                    </div>
                    <div className='industries-flex'>
                        <img src={Image19} />
                        <h4>Matrimony</h4>
                    </div>
                    <div className='industries-flex'>
                        <img src={Image20} />
                        <h4>Art</h4>
                    </div>
                </Col>
                <Col xs={12} md={8} lg={4}>
                    <div className='industries-flex'>
                        <img src={Image21} />
                        <h4>Communication</h4>
                    </div>
                    <div className='industries-flex'>
                        <img src={Image22} />
                        <h4>Entertainment</h4>
                    </div>
                    <div className='industries-flex'>
                        <img src={Image23} />
                        <h4>Environmental</h4>
                    </div>
                    <div className='industries-flex'>
                        <img src={Image24} />
                        <h4>Fashion</h4>
                    </div>
                    <div className='industries-flex'>
                        <img src={Image25} />
                        <h4>Spa</h4>
                    </div>
                </Col>
                <Col xs={12} md={8} lg={4}>
                    <div className='industries-flex'>
                        <img src={Image26} />
                        <h4>Children</h4>
                    </div>
                    <div className='industries-flex'>
                        <img src={Image27} />
                        <h4>Craft</h4>
                    </div>
                    <div className='industries-flex'>
                        <img src={Image28} />
                        <h4>Music</h4>
                    </div>
                    <div className='industries-flex'>
                        <img src={Image29} />
                        <h4>Navigation</h4>
                    </div>
                    <div className='industries-flex'>
                        <img src={Image30} />
                        <h4>News</h4>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Industries;